<template>
  <v-parallax
      dark="true"
      height="auto"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>Dead End</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6">
          <v-card-text>
            {{this.$store.state.deathText}}
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6">
          <v-card-title>Topics</v-card-title>
          <v-card-text>
            <a :href="this.$store.state.deathLinks"> {{this.$store.state.deathTopic }} </a>
          </v-card-text>
          <v-btn @click="newGame">Start again?</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "DeadEnd",
  methods: {
    newGame() {
      this.$store.commit("newGame")
    }
  }
}
</script>

<style scoped>

</style>