<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/goals.jpeg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>SCENE 5</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>The idea of observation was to eliminate the hard work wherever possible. In addition, you realize how it
              could help meet the 13-month target. You decide to set improvement expectations based on the 3C Framework.  Choose a "C" to begin: </p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered">
          <v-card-title>Choices</v-card-title>
          <div class="pa-3">
            <v-btn @click="answer(-2, 1)">Content</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-2, 2)">Characteristics</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-2, 3)">Categories</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" color="teal lighten-3">
          <v-card-text>
            <p class="font-weight-bold">Click on the next one <span class="green--text">(-20 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p><a href="https://rise.articulate.com/share/ngw3wG_DYH2PU-ZRHRzh-2wQkzRS5dWU">Video of Microsoft’s explanation of the 3C</a></p>
          </v-card-text>
                    <v-btn @click="nextScene">Next!</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "FifthStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
  }),
  methods: {
    answer(amount, numTopic) {
      this.isAnswered = true
      this.$store.commit("changeDays", amount)
      this.topic = numTopic
    },
    nextScene() {
      this.$store.commit("sixthStep", this.topic)
    }
  },
}
</script>

<style scoped>

</style>