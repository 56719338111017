<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/safetyAccident.jpeg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>SCENE 6</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>Working with your team, you have focused on making work more cyclical!  BUT you bring different teams
              together to share the progress, you end up wasting a lot of time trying to get everybody on the same page.
              What would help everyone to understand quickly the current state?</p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered">
          <v-card-title>Choices</v-card-title>
          <div class="pa-3">
            <v-btn @click="answer(-5, 1)">Host daily team meetings</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(+666, 2)">Initiate periodic coordination council with reports</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-10, 3)">Develop visual management</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" color="teal lighten-3">
          <v-card-text>
            <p v-if="topic === 1" class="font-weight-bold">you gather daily and each manager reports; but you spend a lot
              of time on unnecessary details rather than improvement efforts to meet the targets. By listening carefully,
              you notice that managers are waiting to report--not listening to each other.  As a result, people repeat
              information over and over for those who missed it. How to make the daily meetings more efficient?
              <span class="green--text">( -5 days)</span></p>
            <p v-if="topic === 3" class="font-weight-bold">you equip one common room with a visual representation of the
              work flow:  to be completed, completed, results. You gather and report just the results, which builds enthusiasm.
              <span class="green--text">(-10 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p v-if="topic === 1"><a href="https://www.lean.org/lexicon-terms/huddles/">Stand up meetings</a></p>
            <p v-if="topic === 3"><a href="https://www.lean.org/lexicon-terms/obeya/">OBEYA</a></p>
          </v-card-text>
          <v-btn @click="nextScene">Finish!</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "SixthStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
  }),
  methods: {
    answer(amount, numTopic) {
      let death = {
        dtext: "you had 2 Coordination council meetings and heard that people are not happy with them. First, the meetings take too much preparation time. Secondly, you attempt to address all questions and problems during the meetings. As a result, experiment and countermeasures are few and far between. (+10 days) DEAD END",
        dtopics: "Agile vs waterfall"
      }
      if (amount !== +666) {
        this.isAnswered = true
        this.$store.commit("changeDays", amount)
        this.topic = numTopic
      }
      else {
        this.$store.commit("deathEnd", death)
      }
    },
    nextScene() {
      this.$store.commit("finalStep", this.topic)
    }
  },
}
</script>

<style scoped>

</style>