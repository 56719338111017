<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/agreement.jpg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>SCENE 3</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>Finally the team is energized and eager to collaborate. You mutually agree on 3 goals:</p>
            <p>• Reduce construction time (lead time to site completion) from 15 to 13 months.</p>
            <p>• Reduce safety incidents from 0.9 to 0.3</p>
            <p>• Improve quality to ensure 0 major issues at handoff.</p>
            <p>You have documented all the processes and details, along with concerns from the team about what needs improvement. How do you proceed? </p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered">
          <v-card-title>Choices</v-card-title>
          <div class="pa-3">
            <v-btn @click="answer(+666, 1)">prioritize the teams' concerns and form a road map to work on them</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(+10, 2)">study the teams' concerns to properly define each problem</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-10, 3)">go and observe the whole work process</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" color="teal lighten-3">
          <v-card-text>
            <p v-if="topic === 2" class="font-weight-bold">You appreciate that some managers raise concerns along with
              proposed solutions. However, the first countermeasures does not eliminate the problem. You have to figure out
              how to better understand what contributes to the problem. <span class="red--text">(+10 days)</span></p>
            <p v-if="topic === 3" class="font-weight-bold">You see right away how hard construction work is and decide to
              measure the number of times workers bend, reach, and lift heavy materials. Here's an example for you try
              practicing your own observation skills.<span class="green--text">(-10 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p v-if="topic === 2"><a href="https://rise.articulate.com/share/Ob7FgXYFRkgwFn8C1Q3Fm1doVgq6vncL">Problem break-down and analysis</a></p>
            <p v-if="topic === 3">Work observation video</p>
          </v-card-text>
          <v-btn @click="nextScene">Next!</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "ThirdStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
  }),
  methods: {
    answer(amount, numTopic) {
      let death = {
        dtext: "It is almost impossible to agree on the importance of concerns. Every unit and contractor insists on the importance of their own work. You make a tough decision based on opinion, which affects the motivation of all team members. DEAD END",
        dtopics: "Sources of internal motivation (autonomy, mastery, purpose) [link to Daniel Pink's Drive overview]",
        dlinks: "https://www.tutor2u.net/business/reference/motivation-pink-three-elements-of-intrinsic-motivation"
      }
      if (amount !== +666) {
        this.isAnswered = true
        this.$store.commit("changeDays", amount)
        this.topic = numTopic
      } else {
        this.$store.commit("deathEnd", death)
      }
    },
    nextScene() {
      this.$store.commit("fourthStep", this.topic)
    }
  }
}
</script>

<style scoped>

</style>