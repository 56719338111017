<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <span class="mr-2">Microsoft story</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        target="_blank"
        text
      >
        <span class="mr-2">Days behind schedule: <span class="red--text">{{this.$store.state.days}}</span></span>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <EntryComponent v-if="this.$store.state.showEntry"/>
      <DeadEnd v-if="this.$store.state.showDeath"/>
      <FirstStep v-if="this.$store.state.showFirst"/>
      <SecondStep v-if="this.$store.state.showSecond"/>
      <ThirdStep v-if="this.$store.state.showThird"/>
      <FourthStep v-if="this.$store.state.showFourth" />
      <FifthStep v-if="this.$store.state.showFifth" />
      <SixthStep v-if="this.$store.state.showSixth" />
      <FinalStep v-if="this.$store.state.showFinal" />
    </v-main>
  </v-app>
</template>

<script>
import EntryComponent from "@/components/EntryComponent";
import DeadEnd from "@/components/DeadEnd";
import FirstStep from "@/components/FirstStep";
import SecondStep from "@/components/SecondStep";
import ThirdStep from "@/components/ThirdStep";
import FourthStep from "@/components/FourthStep";
import FifthStep from "@/components/FifthStep";
import SixthStep from "@/components/SixthStep";
import FinalStep from "@/components/FinalStep";
import store from "@/store";

export default {
  name: 'App',
  store,
  components: {
    EntryComponent,
    DeadEnd,
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    SixthStep,
    FinalStep,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">
$color-pack: false

@import '~vuetify/src/styles/main.sass'
</style>
