<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/safetyAccident.jpeg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>SCENE 2</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p><span v-if="this.$store.state.firstAnswer === 1 || this.$store.state.firstAnswer === 2">After asking several
              managers and reading through incident reports, you notice a theme "operator error".</span>If everyone believes
              that safety incidents are simply a worker's fault, then how can we inspire improvement efforts? You refuse to
              accept such a blame-filled answer. After doing more research, you notice a pattern: many safety incidents occur
              after a handoff. What’s your next step?</p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered">
          <v-card-title>Choices</v-card-title>
          <div class="pa-3">
            <v-btn @click="answer(-2, 1)">work with teams on construction site</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(+5, 2)">interview operational teams that face the problems</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(+666, 3)">start reviewing handoff standards</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" color="teal lighten-3">
          <v-card-text>
            <p v-if="topic === 1" class="font-weight-bold">To access those people who are doing the actual work you need
              team support. This is where the previous director failed. One by one you win the attention and support of the
              management team. Your efforts are rewarded! By developing alignment with your immediate managers, they become
              more motivated to make improvements and let you near the work in process. Here you have some tips on (a) persuasive
              communication (b) empathy (c) argumentation <span class="green--text">( -2 days)</span></p>
            <p v-if="topic === 2" class="font-weight-bold">The interviews are unproductive. Operational teams seem to get
              rattled as they recall the handoff problems. Your team is defensive and appears demotivated. Looks like you
              need some tips on how to handle hard meetings.<span class="red--text">(+5 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p><v-btn v-if="topic === 1">(a) A continuous improvement culture of engagement</v-btn></p>
            <p><v-btn v-if="topic === 1">(b) empathy</v-btn></p>
            <p><v-btn v-if="topic === 1">(c) argumentation</v-btn></p>
            <p v-if="topic === 2">Hard meetings</p>
          </v-card-text>
          <v-btn @click="nextScene">Next!</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="topic === 1">
      <v-col sm="12" md="8" offset-md="2">
        <v-card color="cyan lighten-5">
          <v-card-title class="teal--text text-center">A CONTINUOUS IMPROVEMENT
            CULTURE OF ENGAGEMENT</v-card-title>
          <v-divider class="py-6"></v-divider>
          <v-card-text class="text-center">
            A strong, innate social need to be connected to and accepted by others
          </v-card-text>
          <v-row>
            <v-col md="6" offset-md="3">
              <v-img src="../assets/HeirarchyOfNeeds.png" class="ma-3"></v-img>
            </v-col>
            <v-col >

            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" class="mx-6">
              <v-card>
                <v-card-title>2 regions of brain activated on physical hurt:</v-card-title>
                <v-card-text>
                  <p> - bodily sensation</p>
                  <p> - feelings</p>
                  <p>Rejection show the same feeling region activation</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-img src="../assets/painbrain.png"></v-img>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-row>
            <v-col md="8" offset-md="2">
              <v-card>
                <v-card-title>At work the threats to our social wellbeing usually come in the form of what is said, how it is said and how it affects our standing in the group.</v-card-title>
                <v-card-text>
                  <p>Social situations in which our neural network that responds to threats is activated:</p>
                  <p> * any condition or action that lessen our status or appearance of competence</p>
                  <p> * something that makes us feel uncertain amid lack or unclarity of information as to what is expected of us</p>
                  <p> * seem to us unfair</p>
                  <p> * make us feel not accepted by groups which are important to us</p>
                  <p> * when we are losing key relationships</p>
                  <h2>72% OF 60000 EMPLOYEES CONSIDER THAT RESULTS-FOCUS AND SOCIAL FOCUS ARE ESSENTIAL FOR A "GREAT LEADER."</h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-row>
            <v-col md="8" offset-md="2">
              <v-card>
                <v-card-title>
                  Forms of expressions a manager or leader uses that provoke a threat response in our brains even if we are not able to push back outwardly:
                </v-card-title>
                <v-card-text>
                  <p> - commands </p>
                  <p> - demands </p>
                  <p> - public feedback or rebukes</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-row>
            <v-col md="8" offset-md="2">
              <v-card>
                <v-card-title>
                  Basic 3 "don't" rule of communication:
                </v-card-title>
                <v-card-text>
                  <p>Don't criticize. Don't judge. Don't complain.</p>
                  <p>If a person has trusted you, so be kind enough to listen and help!</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-row>
            <v-col md="8" offset-md="2">
              <v-card>
                <v-card-title><a href="" target="_blank">For more info: Manager-Employee Communication. What Neuroscience tell us </a></v-card-title>
                <v-card-text class="text--accent-2">Also you may wont to read</v-card-text>
              </v-card>
              <v-divider class="ma-6"></v-divider>
              <p><a href="https://leanenterpriseinstitute.sharepoint.com/:p:/g/EaPLq4eNQsBGoebUUZ5aptABNsoRE7oZw7FWsT0K7qTdMg?e=FBsadp" target="_blank">1.Leaders’ Actions Speak But Their Talk Matters Too</a></p>
              <p><a href="https://leanenterpriseinstitute.sharepoint.com/:b:/g/EXnH51dnGCdLml_TmQt508MBP3-8YyJozYxrY8CzgrLPCw?e=SOeq0P" target="_blank">2.Real Respect Feels Like Knowing You’re Being Heard</a></p>
              <p><a href="https://www.canva.com/design/DAFSNl5ebaM/RdNS3qtw_u0hQ9TEzmnmRg/edit?utm_content=DAFSNl5ebaM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank">3.Be a Better Coach; Learn to “Force” Reflection, Part 1</a></p>
              <p><a href="https://www.canva.com/design/DAFSNhkMYCM/9qCwPVI-CiHyW3b24oC2kQ/edit?utm_content=DAFSNhkMYCM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank">4.Be a Better Coach; Learn to “Force” Reflection Part 2: Forcing Managers and Execs to Reflect</a></p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="topic === 2">
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="pa-10" color="cyan lighten-5">
          <v-card-title>Managing difficult negotiations</v-card-title>
          <v-divider class="ma-6"></v-divider>
          <v-card-text>
            Regardless of the manipulative tactic used by the other party, we recommend responding with our
            TACTIC model for adversarial negotiations:
          </v-card-text>
          <v-card-text>
            <p> * Take a deep breath</p>
            <p> * Acknowledge the disagreement</p>
            <p> * Clarify the concern</p>
            <p> * Transfer the focus</p>
            <p> * Investigate mutually beneficial alternatives</p>
            <p> * Confirm next steps</p>
          </v-card-text>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Take a Deep Breath</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>1</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                The goal is to allow you to digest the situation while remaining calm and professional before you
                respond or react in a way that doesn’t protect your interests.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Acknowledge the disagreement</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>2</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                People like to be acknowledged and heard, even if you aren’t immediately agreeing with them.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Clarify the concern</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>3</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                This has the added benefit of helping ensure you respond to the actual concern,
                and not one you've misinterpreted or fabricated in your head.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Transfer the focus</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>4</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                Transferring the focus is making an effort to shift attention away from the most contentious
                issue and reframe the discussion around the shared interests that build the backbone of working together.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Investigate mutually beneficial alternatives</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>5</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                To understand the other side’s perspective and investigate the “Interests behind the positions”
                we often find that there is more than one way to resolve a sticking point.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-card-text class="text-center">Confirm next steps</v-card-text>
          <v-row>
            <v-col md="4" class="text-center">
              <h1>6</h1>
            </v-col>
            <v-col md="8">
              <v-card-text>
                Once you’ve Taken a deep breath, Acknowledged the disagreement, Clarified the concerns, Transferred the
                focus back onto shared interests, and Investigated alternatives, it is time to test the waters, determine
                if you’ve made progress, and Confirm the next steps. The best way to do this is to ask the other party.
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="ma-6"></v-divider>
          <v-row>
            <v-col md="8" offset-md="2">
              <v-card>
                <v-card-title><a href="" target="_blank">For more info: Manager-Employee Communication. What Neuroscience tell us </a></v-card-title>
                <v-card-text class="text--accent-2">Also you may wont to read</v-card-text>
              </v-card>
              <v-divider class="ma-6"></v-divider>
              <p><v-icon class="teal--text ">mdi-lightbulb-on</v-icon> <a href="https://leanenterpriseinstitute.sharepoint.com/:p:/g/EaPLq4eNQsBGoebUUZ5aptABNsoRE7oZw7FWsT0K7qTdMg?e=FBsadp" target="_blank">  Five Sales Negotiations Tactics to Use with Procurement</a></p>
              <p><v-icon class="teal--text ">mdi-lightbulb-on</v-icon> <a href="https://leanenterpriseinstitute.sharepoint.com/:b:/g/EXnH51dnGCdLml_TmQt508MBP3-8YyJozYxrY8CzgrLPCw?e=SOeq0P" target="_blank">  5 Counter Negotiation Tactics When Buyer Makes the First Offer</a></p>
              <p><v-icon class="teal--text ">mdi-lightbulb-on</v-icon> <a href="https://www.canva.com/design/DAFSNl5ebaM/RdNS3qtw_u0hQ9TEzmnmRg/edit?utm_content=DAFSNl5ebaM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank">  3 Types of Negotiation Tactics and How to Respond</a></p>
              <p><v-icon class="teal--text ">mdi-lightbulb-on</v-icon> <a href="https://www.canva.com/design/DAFSNhkMYCM/9qCwPVI-CiHyW3b24oC2kQ/edit?utm_content=DAFSNhkMYCM&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank">  13 Tactics to Prevent a Heated Price Negotiation</a></p>
              <p><v-icon class="teal--text ">mdi-lightbulb-on</v-icon> <a href="https://leanenterpriseinstitute.sharepoint.com/:p:/g/EaPLq4eNQsBGoebUUZ5aptABNsoRE7oZw7FWsT0K7qTdMg?e=FBsadp" target="_blank">  Effective Sales Negotiation Process to Increase Leverage</a></p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="ma-6"></v-divider>
  </v-parallax>
</template>

<script>
export default {
  name: "SecondStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
  }),
  methods: {
    answer(amount, numTopic) {
      let death = {
        dtext: "Standards are well-developed. Although...there is a lot of text to read and the jargon confuses new crew members. Some people did not following the process as described in the documentation, leading to safety problems. How to make it easy to follow the standards? ...DEAD END",
        dtopics: "New culture of written documents Job Instruction Template Jidoka"
      }
      if (amount !== +666) {
        this.isAnswered = true
        this.$store.commit("changeDays", amount)
        this.topic = numTopic
      }
      else {
        this.$store.commit("deathEnd", death)
      }
    },
    nextScene() {
      this.$store.commit("thirdStep", this.topic)
    }
  },
}
</script>

<style scoped>

</style>