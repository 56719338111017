import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        days: 120,
        showEntry: true,
        showDeath: false,
        showFirst: false,
        showSecond: false,
        showThird: false,
        showFourth: false,
        showFifth: false,
        showSixth: false,
        showFinal: false,
        firstAnswer: 0,
        secondAnswer: 0,
        thirdAnswer: 0,
        fourthAnswer: 0,
        fifthAnswer: 0,
        sixthAnswer: 0,
        deathText: "",
        deathTopic: "",
        deathLinks: ""
    },
    mutations: {
        changeDays(state, amount) {
            state.days += amount;
        },
        deathEnd(state, death) {
            state.deathText = death.dtext;
            state.deathTopic = death.dtopics;
            state.deathLinks = death.dlinks;
            state.showSecond = false;
            state.showThird = false;
            state.showSixth = false;
            state.showDeath = true;
        },
        newGame(state) {
            state.showEntry = true;
            state.showFirst = false;
            state.showSecond = false;
            state.showThird = false;
            state.showFourth = false;
            state.showFifth = false;
            state.showSixth = false;
            state.showFinal = false;
            state.showDeath = false;
            state.days = 120;
        },
        firstStep(state) {
            state.showEntry = false;
            state.showFirst = true;
        },
        secondStep(state, answer) {
            state.showFirst = false;
            state.showSecond = true;
            state.firstAnswer = answer;
        },
        thirdStep(state, answer) {
            state.showSecond = false;
            state.showThird = true;
            state.secondAnswer = answer;
        },
        fourthStep(state, answer) {
            state.showThird = false;
            state.showFourth = true;
            state.thirdAnswer = answer;
        },
        fifthStep(state, answer) {
            state.showFourth = false;
            state.showFifth = true;
            state.fourthAnswer = answer;
        },
        sixthStep(state, answer) {
            state.showFifth = false;
            state.showSixth = true;
            state.fifthAnswer = answer;
        },
        finalStep(state, answer) {
            state.showSixth = false;
            state.showFinal = true;
            state.sixthAnswer = answer;
        },
    }
})
