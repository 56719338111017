<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/meetingBackground.jpeg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10" :color="ready">
          <v-card-text class="text-center">
            <h1>SCENE 1</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p> At a board meeting; 8 people sitting around an oblong table with lots of papers spread across it and
              rough blue prints on the walls in the background; person at the head of the table is pointing to a piece
              of paper in front of them with a serious look on their face; they look up at us through their eyebrows.  </p>
          </v-card-text>
          <v-card-text>
            <p><span class="font-weight-bold">BOARD MEMBER:</span> - As you know time is of the essence; we have 13 months to complete this data center.
              But we cannot sacrifice safety. We need to reduce our safety incident rate from 0.9 to 0.3 by the end of the project.
              We can send you some documentation... </p>
            <p><span class="font-weight-bold">Timeline:</span> <span class="red--text">120 days behind schedule!</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered" >
          <v-card-title>Choices</v-card-title>
          <v-card-text>
            <p>You need to reduce safety incidents from 0.9 to 0.3. Where do you start?</p>
          </v-card-text>
          <div class="pa-3">
            <v-btn @click="answer(+3, 1)">Read safety incident reports</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(+5, 2)">Gather all your managers to explain the goals</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-2, 3)">observe the work in process</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" :color="ready">
          <v-card-text>
            <p v-if="topic === 1" class="font-weight-bold">You waste 3 days on all the documentation and find out that it is almost impossible to
              figure out how to prevent new accidents since every report concludes the same way...“operator error”. Looks
              like you have to reshape the practice of documenting safety incidents so that the reports are more helpful.
              Who does the best job on that? <span class="red--text">(+3 days)</span></p>
            <p v-if="topic === 2" class="font-weight-bold">Your managers argue that the target of 0.3 is unrealistic since construction incident
              rates worldwide are between 2 and 3. If they do not believe in the goal, how will they help to reach it?
              Do you want to see a couple of examples of goal setting? <span class="red--text">(+5 days)</span></p>
            <p v-if="topic === 3" class="font-weight-bold">You go to observe workers in action, but how to approach them? Basically they are
              contractors and even subcontractors and it is not easy to watch them working without causing a distraction.
              hey look at their manager who calls to another manager. You realize there's more to observation than simply
              showing up. Take a look at some Gemba’s principles to help you out. <span class="green--text">(-2 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p v-if="topic === 1">Best practices of safe incidents handling</p>
            <p v-if="topic === 2">(1) Goal setting.</p>
            <p v-if="topic === 2">(2) How to engage other</p>
            <p v-if="topic === 3"><a href="https://www.lean.org/lexicon-terms/gemba/" target="_blank">Gemba</a></p>
          </v-card-text>
          <v-btn @click="nextScene">Next!</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row>

    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "FirstStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
    ready: "",
  }),
  methods: {
    answer(amount, numTopic) {
      this.isAnswered = true
      this.$store.commit("changeDays", amount)
      this.topic = numTopic
      this.ready = "teal lighten-3"
    },
    nextScene() {
      this.$store.commit("secondStep", this.topic)
    }
  },
}
</script>

<style scoped>

</style>