<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/microsoftBackground.webp"
  >
    <v-row >
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h2>Congratulations, you have made progress toward the three established goals. </h2>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>You want to disseminate the learning to the remaining work sites. Based on the changes made, your team
              develops a standardized work package that makes key steps visual and easy to follow. You also connect people
              who work in similar roles at different job sites so that they can continue to learn and reflect together.
              Curious to keep going? Check out more about Job Instruction and Standardized Work or play again and discover
              different learning paths. </p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" >
          <v-card-title></v-card-title>
          <v-card-text>
            <p><a href="https://www.lean.org/wp-content/uploads/2021/01/Standardized-Work-Job-Instruction-Sheet.pdf">Job Instruction</a></p>
          </v-card-text>
          <v-btn @click="firstStep()">Start again?</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "FinalStep",
  methods: {
    firstStep() {
      this.$store.commit('newGame')
    }
  }
}
</script>

<style scoped>

</style>