<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/microsoftBackground.webp"
  >
    <v-row >
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h2>Microsoft Building these data centers safer, faster, and cheaper</h2>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>Congratulations! You are the new Global Director of Lean Construction Transformation for Microsoft.
              Your first assignment: building 30 data centers in 30 different locations around the world. Each one takes
              around 15 months. You were hired because the previous director had a major conflict with management and was fired
              after four months.  To prove your worth, you decide to start with one  center
              and then use it as a model for the remaining 29. </p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" >
          <v-card-text>
            <p>User could be given a choice of starting location. For example ,in USA or Germany, Ethiopia or even Ukraine. </p>
          </v-card-text>
          <v-btn @click="firstStep()">Are you ready?</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "EntryComponent",
  methods: {
    firstStep() {
      this.$store.commit('firstStep')
    }
  }
}
</script>

<style scoped>

</style>