<template>
  <v-parallax
      dark="true"
      height="auto"
      src="../assets/goals.jpeg"
  >
    <v-row>
      <v-col sm="12" md="8" offset-md="2">
        <v-card class="ma-10">
          <v-card-text class="text-center">
            <h1>SCENE 4</h1>
          </v-card-text>
        </v-card>
        <v-card class="ma-10">
          <v-card-text>
            <p>You realize the advantages of studying the work in a quantifiable way. Given your goals, what should you focus on measuring?</p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="!this.isAnswered">
          <v-card-title>Choices</v-card-title>
          <div class="pa-3">
            <v-btn @click="answer(-20, 1)">Number of materials and tools shared and passed between workers</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-20, 2)">Amount of waiting time spent for the next worker to complete use of shared tools</v-btn>
          </div>
          <div class="pa-3">
            <v-btn @click="answer(-20)">Number of times carts move to collect materials and tools</v-btn>
          </div>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered" color="teal lighten-3">
          <v-card-text>
            <p class="font-weight-bold">All of the measures are necessary - studying the work in a
              quantifiable way helps you to categorize the work. Would you take a look at the 4M’s...<span class="green--text">(-20 days)</span></p>
          </v-card-text>
        </v-card>
        <v-card class="ma-10 text-center pb-6" v-if="isAnswered">
          <v-card-title>Topics to explore</v-card-title>
          <v-card-text>
            <p><a href="https://www.lean.org/lexicon-terms/basic-stability/">4M's</a></p>
          </v-card-text>
          <v-btn @click="nextScene">Next!</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: "FourthStep",
  data: () => ({
    isAnswered: false,
    topic: 0,
  }),
  methods: {
    answer(amount, numTopic) {
      this.isAnswered = true
      this.$store.commit("changeDays", amount)
      this.topic = numTopic
    },
    nextScene() {
      this.$store.commit("fifthStep", this.topic)
    }
  },
}
</script>

<style scoped>

</style>